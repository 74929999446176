export const RETENTION_RULES_ARRAY = [
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "day",
        "checkinterval_value": "2",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "week",
        "duration_value": "7",
        "translations": {
            "DE": {
                "printtext": "7 Tage",
                "name": "7 Tage"
            },
            "GB": {
                "printtext": "7 Days",
                "name": "7 Days"
            }
        },
        "id": "ID_RETENTION_SEVENDAYS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "week",
        "checkinterval_value": "2",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "week",
        "duration_value": "4",
        "translations": {
            "DE": {
                "printtext": "4 Wochen",
                "name": "4 Wochen"
            },
            "GB": {
                "printtext": "4 Weeks",
                "name": "4 Weeks"
            }
        },
        "id": "ID_RETENTION_FOURWEEKS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "week",
        "checkinterval_value": "2",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "week",
        "duration_value": "10",
        "translations": {
            "DE": {
                "printtext": "10 Wochen",
                "name": "10 Wochen"
            },
            "GB": {
                "printtext": "10 Weeks",
                "name": "10 Weeks"
            }
        },
        "id": "ID_RETENTION_TENWEEKS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "month",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "month",
        "duration_value": "3",
        "translations": {
            "DE": {
                "printtext": "3 Monate",
                "name": "3 Monate"
            },
            "GB": {
                "printtext": "3 Months",
                "name": "3 Months"
            }
        },
        "id": "ID_RETENTION_THREEMONTHS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "month",
        "checkinterval_value": "3",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "month",
        "duration_value": "6",
        "translations": {
            "DE": {
                "printtext": "6 Monate",
                "name": "6 Monate"
            },
            "GB": {
                "printtext": "6 Months",
                "name": "6 Months"
            }
        },
        "id": "ID_RETENTION_SIXMONTHS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "month",
        "checkinterval_value": "6",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "month",
        "duration_value": "12",
        "translations": {
            "DE": {
                "printtext": "4 Quartale",
                "name": "4 Quartale"
            },
            "GB": {
                "printtext": "4 Quarters",
                "name": "4 Quarters"
            }
        },
        "id": "ID_RETENTION_FOURQUARTER",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "month",
        "checkinterval_value": "6",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "month",
        "duration_value": "12",
        "translations": {
            "DE": {
                "printtext": "1 Jahr",
                "name": "1 Jahr"
            },
            "GB": {
                "printtext": "1 Year",
                "name": "1 Year"
            }
        },
        "id": "ID_RETENTION_ONEYEAR",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "month",
        "checkinterval_value": "6",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "month",
        "duration_value": "13",
        "translations": {
            "DE": {
                "printtext": "13 Monate",
                "name": "13 Monate"
            },
            "GB": {
                "printtext": "13 Months",
                "name": "13 Months"
            }
        },

        "id": "ID_RETENTION_THIRTEEN",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "month",
        "checkinterval_value": "6",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "month",
        "duration_value": "14",
        "translations": {
            "DE": {
                "printtext": "14 Monate",
                "name": "14 Monate"
            },
            "GB": {
                "printtext": "14 Months",
                "name": "14 Months"
            }
        },
        "id": "ID_RETENTION_FOURTEENMONTHS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "2",
        "translations": {
            "DE": {
                "printtext": "2 Jahre",
                "name": "2 Jahre"
            },
            "GB": {
                "printtext": "2 Years",
                "name": "2 Years"
            }
        },
        "id": "ID_RETENTION_TWOYEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "3",
        "translations": {
            "DE": {
                "printtext": "3 Jahre",
                "name": "3 Jahre"
            },
            "GB": {
                "printtext": "3 Years",
                "name": "3 Years"
            }
        },
        "id": "ID_RETENTION_THREEYEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "4",
        "translations": {
            "DE": {
                "printtext": "4 Jahre",
                "name": "4 Jahre"
            },
            "GB": {
                "printtext": "4 Years",
                "name": "4 Years"
            }
        },
        "id": "ID_RETENTION_FOURYEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "month",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "48",
        "translations": {
            "DE": {
                "printtext": "16 Quartale",
                "name": "16 Quartale"
            },
            "GB": {
                "printtext": "16 Quarters",
                "name": "16 Quarters"
            }
        },
        "id": "ID_RETENTION_SIXTEENQUARTER",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "5",
        "translations": {
            "DE": {
                "printtext": "5 Jahre",
                "name": "5 Jahre"
            },
            "GB": {
                "printtext": "5 Years",
                "name": "5 Years"
            }
        },
        "id": "ID_RETENTION_FIVEYEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "6",
        "translations": {
            "DE": {
                "printtext": "6 Jahre",
                "name": "6 Jahre"
            },
            "GB": {
                "printtext": "6 Years",
                "name": "6 Years"
            }
        },
        "id": "ID_RETENTION_SIXYEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "7",
        "translations": {
            "DE": {
                "printtext": "7 Jahre",
                "name": "7 Jahre"
            },
            "GB": {
                "printtext": "7 Years",
                "name": "7 Years"
            }
        },
        "id": "ID_RETENTION_SEVENYEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "8",
        "translations": {
            "DE": {
                "printtext": "8 Jahre",
                "name": "8 Jahre"
            },
            "GB": {
                "printtext": "8 Years",
                "name": "8 Years"
            }
        },
        "id": "ID_RETENTION_EIGHTYEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "10",
        "translations": {
            "DE": {
                "printtext": "10 Jahre",
                "name": "10 Jahre"
            },
            "GB": {
                "printtext": "10 Years",
                "name": "10 Years"
            }
        },
        "id": "ID_RETENTION_TENYEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH",
            "AT",
            "LE",
            "CH",
            "F"
        ],
        "checkinterval_unit": "year",
        "duration_value": "15",
        "translations": {
            "DE": {
                "printtext": "15 Jahre",
                "name": "15 Jahre"
            },
            "GB": {
                "printtext": "15 Years",
                "name": "15 Years"
            }
        },
        "id": "ID_RETENTION_FIFTEENYEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "20",
        "translations": {
            "DE": {
                "printtext": "20 Jahre",
                "name": "20 Jahre"
            },
            "GB": {
                "printtext": "20 Years",
                "name": "20 Years"
            }
        },
        "id": "ID_RETENTION_TWEENTYYEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "30",
        "translations": {
            "DE": {
                "printtext": "30 Jahre",
                "name": "30 Jahre"
            },
            "GB": {
                "printtext": "30 Years",
                "name": "30 Years"
            }
        },
        "id": "ID_RETENTION_THIRTYYEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "40",
        "translations": {
            "DE": {
                "printtext": "40 Jahre",
                "name": "40 Jahre"
            },
            "GB": {
                "printtext": "40 Years",
                "name": "40 Years"
            }
        },
        "id": "ID_RETENTION_FOURTYYEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "50",
        "translations": {
            "DE": {
                "printtext": "50 Jahre",
                "name": "50 Jahre"
            },
            "GB": {
                "printtext": "50 Years",
                "name": "50 Years"
            }
        },
        "id": "ID_RETENTION_FIFTYYEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "60",
        "translations": {
            "DE": {
                "printtext": "60 Jahre",
                "name": "60 Jahre"
            },
            "GB": {
                "printtext": "60 Years",
                "name": "60 Years"
            }
        },
        "id": "ID_RETENTION_SIXTYYEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "-1",
        "translations": {
            "DE": {
                "printtext": "bis zur Vollendung des 28. Lebensjahres der Person",
                "name": "bis zur Vollendung des 28. Lebensjahres der Person"
            },
            "GB": {
                "printtext": "until the person reaches the age of 28",
                "name": "until the person reaches the age of 28"
            }
        },
        "id": "ID_RETENTION_UNTILPATIENTIS28YEARS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "duration_unit": "day",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "week",
        "duration_value": "1",
        "translations": {
            "DE": {
                "printtext": "keine Aufbewahrung, umgehende L\u00f6schung",
                "name": "keine Aufbewahrung, umgehende L\u00f6schung"
            },
            "GB": {
                "printtext": "no retention immediate deletion",
                "name": "no retention immediate deletion"
            }
        },
        "id": "ID_RETENTION_IMMEDIATELY",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE"
        ],
        "duration_unit": "",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "-1",
        "translations": {
            "DE": {
                "printtext": "bis zum Ablauf des auf die letzte Betriebsprüfung folgenden Jahres",
                "name": "bis zum Ablauf des auf die letzte Betriebsprüfung folgenden Jahres"
            },
            "GB": {
                "printtext": "until the end of the year following the last tax audit",
                "name": "until the end of the year following the last tax audit"
            }
        },
        "id": "ID_RETENTION_ATLEASTUNTILNEXTAUDIT",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE"
        ],
        "duration_unit": "",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "-1",
        "translations": {
            "DE": {
                "printtext": "bis Ausscheiden",
                "name": "bis Ausscheiden"
            },
            "GB": {
                "printtext": "until leaving",
                "name": "until leaving"
            }
        },
        "id": "ID_RETENTION_UNTILLEAVING",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE"
        ],
        "duration_unit": "week",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "week",
        "duration_value": "-1",
        "translations": {
            "DE": {
                "printtext": "bis auf Widerruf oder Zweckentfall",
                "name": "bis auf Widerruf oder Zweckentfall"
            },
            "GB": {
                "printtext": "until revoked or the purpose is no longer applicable",
                "name": "until revoked or the purpose is no longer applicable"
            }
        },
        "id": "ID_RETENTION_UNTILWITHDRAWLOFCONSENT",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "-1",
        "translations": {
            "DE": {
                "printtext": "unbegrenzt",
                "name": "unbegrenzt"
            },
            "GB": {
                "printtext": "unlimited",
                "name": "unlimited"
            }
        },
        "id": "ID_RETENTION_UNLIMITED",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "-1",
        "translations": {
            "DE": {
                "printtext": "bis Ausscheiden an Sorgeberechtigte oder sofortige Vernichtung",
                "name": "bis Ausscheiden an Sorgeberechtigte oder sofortige Vernichtung"
            },
            "GB": {
                "printtext": "bis leaving custody or immediate deletion",
                "name": "bis leaving custody or immediate deletion"
            }
        },
        "id": "ID_RETENTION_AFTERTDEREGISTRATIONCHILDCARE",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "-1",
        "translations": {
            "DE": {
                "printtext": "bis Vollendung des 18. Lebensjahres",
                "name": "bis Vollendung des 18. Lebensjahres"
            },
            "GB": {
                "printtext": "until 18th birthday",
                "name": "until 18th birthday"
            }
        },
        "id": "ID_RETENTION_UNTILTERMINATIONOFEMPLYMENTOREIGHTEEN",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE"
        ],
        "duration_unit": "year",
        "checkinterval_value": "1",
        "justiceareas": [
            "DE", "CH"
        ],
        "checkinterval_unit": "year",
        "duration_value": "-1",
        "translations": {
            "DE": {
                "printtext": "bis Ende der Beschäftigung, Amts- oder Wahlperiode",
                "name": "bis Ende der Periode"
            },
            "GB": {
                "printtext": "until end of term or period",
                "name": "until end of period"
            }
        },
        "id": "ID_RETENTION_UNTILENDOFTERM",
        "deflanguage": "DE"
    }
];