const momentTZ = require('moment-timezone');

import {
  COUNTRYLIST,
  GDPR_COUNTRYLIST,
  DP_NON_EU_COUNTRYLIST
} from '@/lookup/countries';

import {
  REQUESTSTAGE_LIST,
  REQUESTSTAGE_I18N_KEY
} from '@/lookup/request_stages';

import {
  USERTASKSTAGE_LIST,
  USERTASKSTAGE_I18N_KEY
} from '@/lookup/usertask_stages';

import {
  REQUESTORIGIN_LIST,
  REQUESTORIGIN_I18N_KEY,

  REQUESTCATEGORY_LIST,
  REQUESTCATEGORY_I18N_KEY,

  REQUEST_ORIGIN_CATEGORY_MAP
} from '@/lookup/requests';

import {
  REQUESTVALIDATIONMETHOD_I18N_KEY,
  REQUESTVALIDATIONMETHOD_LIST,
  REQUESTIDENTITYSTATE_LIST,
  REQUESTIDENTITYSTATE_I18N_KEY,
  REQUESTAUTHORIZATIONSTATE_I18N_KEY,
  REQUESTAUTHORIZATIONSTATE_LIST,
  REQUESTNOTIFICATIONSTATE_I18N_KEY,
  REQUESTNOTIFICATIONSTATE_LIST,
  REQUESTPREPARATIONSTATE_I18N_KEY,
  REQUESTPREPARATIONSTATE_LIST,
} from '@/lookup/request_validations';

import {
  ACTIVITYDOCSTAGE_I18N_KEY,
  ACTIVITYDOCSTAGE_LIST
} from '@/lookup/activitydoc_stages';

import {
  INCIDENTSTAGE_LIST,
  INCIDENTSTAGE_I18N_KEY
} from '@/lookup/incident_stages';

import {
  INCIDENTDETECTOR_LIST,
  INCIDENTDETECTOR_I18N_KEY
} from '@/lookup/incident_detectors';

import {
  INCIDENTTITLE_LIST,
  INCIDENTTITLE_I18N_KEY
} from '@/lookup/incident_titles';

import {
  INCIDENTREASON_LIST,
  INCIDENTREASON_I18N_KEY
} from '@/lookup/incident_reasons';

import {
  INCIDENTNOTIFICATIONSTATE_LIST,
  INCIDENTNOTIFICATIONSTATE_I18N_KEY,

  INCIDENTREPORTSTATE_LIST,
  INCIDENTREPORTSTATE_I18N_KEY
} from '@/lookup/incident_notifications';

import {
  INCIDENTRISKCATEGORY_LIST,
  INCIDENTRISKCATEGORY_I18N_KEY
} from '@/lookup/incident_riskcategories';

import {
  INCIDENTRISKCONSEQUENCE_LIST,
  INCIDENTRISKCONSEQUENCE_I18N_KEY
} from '@/lookup/incident_riskconsequences';

import {
  OFFICERTYPE_LIST,
  OFFICERTYPE_I18N_KEY
} from '@/lookup/officer_types';

import {
  IMPACTLEVEL_LIST,
  IMPACTLEVEL_I18N_KEY
} from '@/lookup/impactlevels';

import {
  PROOFTARGET_LIST,
  PROOFTARGET_I18N_KEY
} from '@/lookup/proof_targets';

import {
  PROOFSTAGE_LIST,
  PROOFSTAGE_I18N_KEY
} from '@/lookup/proof_stages';

import {
  PROCEDURESTAGE_LIST,
  PROCEDURESTAGE_I18N_KEY
} from '@/lookup/procedure_stages';

import {
  PROCEDURETARGET_I18N_KEY,
  PROCEDURETARGET_LIST
} from '@/lookup/procedure_targets';

import {
  DSFASTAGE_LIST,
  DSFASTAGE_I18N_KEY
} from '@/lookup/dsfa_stages';

import {
  DSFATARGET_I18N_KEY,
  DSFATARGET_LIST
} from '@/lookup/dsfa_targets';

import {
  THRESHOLDCRITERIA_I18N_KEY,
  THRESHOLDCRITERIA_LIST
} from '@/lookup/threshold_criteria';

import {
  THRESHOLDLEVEL_I18N_KEY,
  THRESHOLDLEVEL_LIST
} from '@/lookup/threshold_levels';

import {
  ENTITYCONTACT_ROLE_I18N_KEY,
  ENTITYCONTACT_ROLE_LIST
} from '@/lookup/entitycontact_roles';

import {
  CONTRACTPARTNERTYPE_I18N_KEY,
  CONTRACTPARTNERTYPE_LIST
} from '@/lookup/contractpartner_types';

import {
  DATAPROCESSORROLE_I18N_KEY,
  DATAPROCESSORROLE_LIST
} from '@/lookup/dataprocessor_roles';

import {
  TIMEINTERVAL_I18N_KEY,
  TIMEINTERVAL_LIST
} from '@/lookup/timeintervals';

import {
  DOCUMENTTYPE_LIST,
  DOCUMENTTYPE_I18N_KEY
} from '@/lookup/document_types';

import {
  DOCUMENTCATEGORY_LIST,
  DOCUMENTCATEGORY_I18N_KEY
} from '@/lookup/document_categories';

import {
  DECOMMISSIONREASON_LIST,
  DECOMMISSIONREASON_I18N_KEY
} from '@/lookup/decommission_reasons';

import {
  TIMELINE_CHANGETYPE_I18N_KEY,
  TIMELINE_CHANGETYPE_LIST
} from '@/lookup/timeline_changetypes';

import {
  PROOFLEVEL_I18N_KEY,
  PROOFLEVEL_LIST
} from '@/lookup/proof_levels';

import {
  ASSETCATEGORY_I18N_KEY,
  ASSETCATEGORY_LIST
} from '@/lookup/asset_categories';

import {
  ASSETTARGET_I18N_KEY,
  ASSETTARGET_LIST
} from '@/lookup/asset_targets';

import {
  RISKCATALOGUE_I18N_KEY,
  RISKCATALOGUE_LIST,
  RISKDANGER_I18N_KEY,
  RISKDANGER_LIST
} from '@/lookup/risk_catalogues';

import {
  RISKBASEVALUE_I18N_KEY,
  RISKBASEVALUE_LIST
} from '@/lookup/risk_basevalues';

import {
  RISKSTAGE_I18N_KEY,
  RISKSTAGE_LIST
} from '@/lookup/risk_stages';

import {
  RISKLEVEL_I18N_KEY,
  RISKLEVELIMPACT_LIST,
  RISKLEVELPROPABILITY_LIST,
  RISKLEVELTOTAL_LIST
} from '@/lookup/risk_levels';

import {
  LOCATIONTYPE_I18N_KEY,
  LOCATIONTYPE_LIST
} from '@/lookup/location_types';

import {
  UITHEME_I18N_KEY,
  UITHEME_LIST
} from '@/lookup/ui_themes';

import {
    RETENTION_RULES_ARRAY
} from "@/lookup/retention_rules";
import {
  RETENTION_STARTTIMES_ARRAY
} from "@/lookup/retention_starttimes";

import i18n from '@/translations';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    //
    // Public
    //
  },
  getters: {
    getterTimezonesAsArray: (state) => {
      return momentTZ.tz.names();
    },
    // Non multi language lookup
    getterFederalStateByCountryList: (state) => country => {
      // get all federal states
      return COUNTRYLIST[country] !== undefined ? COUNTRYLIST[country].regions : [];
    },
    getterCountryList: (state) => {
      //generate an array to be used in a select box containing all countries
      let list = [];

      for (let key in COUNTRYLIST) {
        list.push({
          value: key,
          text: COUNTRYLIST[key].name
        });
      }

      return list;
    },
    getterGDPRCountryList: (state, getters, rootGetters) => {
      //generate an array containing all data protection EU countries
      let list = [];

      for (let key in COUNTRYLIST) {
        if (GDPR_COUNTRYLIST.indexOf(key) !== -1) {
          list.push({
            value: key,
            text: COUNTRYLIST[key].name
          });
        }
      }

      return list;
    },
    getterDPNonEUCountryList: (state, getters, rootGetters) => {
      //generate an array containing all data protection NON EU countries
      let list = [];

      for (let key in COUNTRYLIST) {
        if (DP_NON_EU_COUNTRYLIST.indexOf(key) !== -1) {
          list.push({
            value: key,
            text: COUNTRYLIST[key].name
          });
        }
      }

      return list;
    },
    getterGDPRCountryCodesAsArray: (state, getters, rootGetters) => {
      //generate an array to be used in a select box containing all gdpr countries
      return [...GDPR_COUNTRYLIST];
    },
    getterDPNonEUCountryCodesAsArray: (state, getters, rootGetters) => {
      //generate an array to be used in a select box containing all gdpr countries
      return [...DP_NON_EU_COUNTRYLIST];
    },
    getterThresholdCriteriaIdOther: (state) => {
      return "ID_THRESHOLDCRITERIA_OTHER";
    },
    getterThresholdCriteriaIdBlacklist: (state) => {
      return "ID_THRESHOLDCRITERIA_BLACKLIST";
    },
    getterThresholdCriteriaIdWhitelist: (state) => {
      return "ID_THRESHOLDCRITERIA_WHITELIST";
    },
    getterThresholdLevelIdBlacklist: (state) => {
      return "ID_THRESHOLDLEVEL_BLACKLIST";
    },
    getterThresholdLevelIdLow: (state) => {
      return "ID_THRESHOLDLEVEL_LOW";
    },
    getterThresholdLevelIdMedium: (state) => {
      return "ID_THRESHOLDLEVEL_MEDIUM";
    },
    getterThresholdLevelIdHigh: (state) => {
      return "ID_THRESHOLDLEVEL_HIGH";
    },
    getterThresholdLevelIdWhitelist: (state) => {
      return "ID_THRESHOLDLEVEL_WHITELIST";
    },

    // Retentions
    getterRetentionStartTimesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRetentionStartTimesAsArrayByLc(rootGetters["TranslationManager/getterLanguageAlpha2Code"]);
    },
    getterRetentionRulesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRetentionRulesAsArrayByLc(rootGetters["TranslationManager/getterLanguageAlpha2Code"]);
    },
    getterUIThemesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterUIThemesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterProofLevelsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterProofLevelsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterEntityContactRolesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterEntityContactRolesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterContractPartnerTypesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterContractPartnerTypesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterDataProcessorRolesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterDataProcessorRolesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterTimeIntervalsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterTimeIntervalsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterDocumentTypesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterDocumentTypesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterDocumentTypeById: (state, getters, rootState, rootGetters) => (type_id) => {
      try {
        return getters.getterDocumentTypesAsArray.filter(item => item.id == type_id)[0];
      } catch (err) {
        return false;
      }
    },
    getterDocumentCategoriesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterDocumentCategoriesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterDocumentCategoryById: (state, getters, rootState, rootGetters) => (category_id) => {
      try {
        return getters.getterDocumentCategoriesAsArray.filter(item => item.id == category_id)[0];
      } catch (err) {
        return false;
      }
    },
    getterDecommissionReasonsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterDecommissionReasonsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterDecommissionReasonById: (state, getters, rootState, rootGetters) => (category_id) => {
      try {
        return getters.getterDecommissionReasonsAsArray.filter(item => item.id == category_id)[0];
      } catch (err) {
        return false;
      }
    },
    getterTimelineChangeTypesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterTimelineChangeTypesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterOfficerTypesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterOfficerTypesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRequestStagesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRequestStagesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRequestValidationMethodsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRequestValidationMethodsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRequestIdentityStatesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRequestIdentityStatesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRequestAuthorizationStatesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRequestAuthorizationStatesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRequestNotificationStatesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRequestNotificationStatesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRequestPreparationStatesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRequestPreparationStatesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRequestOriginsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRequestOriginsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRequestCategoriesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRequestCategoriesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRequestCategoryByID: (state, getters, rootState, rootGetters) => (category_id) => {
      try {
        return getters.getterRequestCategoriesAsArray.filter(item => item.id == category_id)[0];
      } catch (err) {
        return false;
      }
    },
    getterRequestCategoriesByOriginIDAsArray: (state, getters, rootState, rootGetters) => (originID) => {
      return getters.getterRequestCategoriesByOriginIDAsArrayByLc(originID, rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterUsertaskStagesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterUsertaskStagesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterProofTargetsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterProofTargetsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterProofStagesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterProofStagesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterIncidentStagesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterIncidentStagesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterIncidentImpactLevelsAsArray: (state, getters, rootState, rootGetters) => {
      // returns an array of incident impact level object with translations
      return getters.getterIncidentImpactLevelsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterIncidentDetectorsAsArray: (state, getters, rootState, rootGetters) => {
      // returns an array of incident detector objects with translations
      return getters.getterIncidentDetectorsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterIncidentTitlesAsArray: (state, getters, rootState, rootGetters) => {
      // returns an array of incident detector objects with translations
      return getters.getterIncidentTitlesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterIncidentReasonsAsArray: (state, getters, rootState, rootGetters) => {
      // returns an array of incident detector objects with translations
      return getters.getterIncidentReasonsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterIncidentNotificationStatesAsArray: (state, getters, rootState, rootGetters) => {
      // returns an array of incident detector objects with translations
      return getters.getterIncidentNotificationStatesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterIncidentReportStatesAsArray: (state, getters, rootState, rootGetters) => {
      // returns an array of incident detector objects with translations
      return getters.getterIncidentReportStatesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterIncidentRiskCategoriesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterIncidentRiskCategoriesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterIncidentRiskConsequencesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterIncidentRiskConsequencesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterActivityDocStagesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterActivityDocStagesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterProcedureStagesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterProcedureStagesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterProcedureTargetsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterProcedureTargetsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterDsfaStagesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterDsfaStagesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterDsfaTargetsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterDsfaTargetsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },

    getterThresholdCriteriaAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterThresholdCriteriaAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterThresholdLevelsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterThresholdLevelsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },

    getterAssetCategoriesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterAssetCategoriesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterAssetTargetsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterAssetTargetsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRiskCataloguesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRiskCataloguesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRiskBaseValuesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRiskBaseValuesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRiskDangersAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRiskDangersAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRiskStagesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRiskStagesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRiskLevelImpactsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRiskLevelImpactsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRiskLevelPropabilitiesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRiskLevelPropabilitiesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterRiskLevelTotalsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterRiskLevelTotalsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterLocationTypesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterLocationTypesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterGdprOfficerTypesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterGdprOfficersTypesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterAspectsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterAspectsAsArrayLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    //
    // Language parameter version
    //
    getterRetentionStartTimesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      var array = [];

      // console.debug("getterRetentionStartTimesAsArrayByLc -lc", lc);
      try {
        for (const item in RETENTION_STARTTIMES_ARRAY) {
          if (RETENTION_STARTTIMES_ARRAY[item].justiceareas
              .includes(rootGetters["GdprManager/getterGdprData"].lawbasis.countrycode)) {
            array.push({
              id: RETENTION_STARTTIMES_ARRAY[item].id,
              name: RETENTION_STARTTIMES_ARRAY[item].translations[lc.toUpperCase()].name,
              printtext: RETENTION_STARTTIMES_ARRAY[item].translations[lc.toUpperCase()].printtext,
            });
          }
        }

        // Sort A-Z based on display_name
        // array.sort(rootGetters["HelperManager/getterArrayOfObjectDynamicSort"]('display_name'));
        return array;
      }
      catch(error) {
        console.error("getterRetentionStartTimesAsArrayByLc - Error", error);
        return [];
      }
    },

    getterRetentionRulesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      var array = [];

      // console.debug("getterRetentionRulesAsArrayByLc -lc", lc);
      try {
        for (const item in RETENTION_RULES_ARRAY) {
          if (RETENTION_RULES_ARRAY[item].justiceareas
              .includes(rootGetters["GdprManager/getterGdprData"].lawbasis.countrycode)) {
            array.push({
              id: RETENTION_RULES_ARRAY[item].id,
              name: RETENTION_RULES_ARRAY[item].translations[lc.toUpperCase()].name,
              printtext:RETENTION_RULES_ARRAY[item].translations[lc.toUpperCase()].printtext,
            });
          }
        }

        // Sort A-Z based on display_name
        // array.sort(rootGetters["HelperManager/getterArrayOfObjectDynamicSort"]('display_name'));
        return array;
      }
      catch(error) {
        console.error("getterRetentionRulesAsArrayByLc - Error", error);
        return [];
      }
    },
    // General file based

    getterUIThemesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(UITHEME_LIST, UITHEME_I18N_KEY, lc, true);
    },
    getterProofLevelsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(PROOFLEVEL_LIST, PROOFLEVEL_I18N_KEY, lc, true);
    },
    getterEntityContactRolesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(ENTITYCONTACT_ROLE_LIST, ENTITYCONTACT_ROLE_I18N_KEY, lc, true);
    },
    getterContractPartnerTypesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(CONTRACTPARTNERTYPE_LIST, CONTRACTPARTNERTYPE_I18N_KEY, lc, true);
    },
    getterDataProcessorRolesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(DATAPROCESSORROLE_LIST, DATAPROCESSORROLE_I18N_KEY, lc, true);
    },
    getterTimeIntervalsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(TIMEINTERVAL_LIST, TIMEINTERVAL_I18N_KEY, lc, false);
    },
    getterDocumentTypesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(DOCUMENTTYPE_LIST, DOCUMENTTYPE_I18N_KEY, lc, true);
    },
    getterDocumentCategoriesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(DOCUMENTCATEGORY_LIST, DOCUMENTCATEGORY_I18N_KEY, lc, true);
    },
    getterDecommissionReasonsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(DECOMMISSIONREASON_LIST, DECOMMISSIONREASON_I18N_KEY, lc, false);
    },
    getterTimelineChangeTypesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(TIMELINE_CHANGETYPE_LIST, TIMELINE_CHANGETYPE_I18N_KEY, lc, false);
    },
    getterOfficerTypesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(OFFICERTYPE_LIST, OFFICERTYPE_I18N_KEY, lc, false);
    },
    getterRequestStagesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of request stage object with translations
      return getters.getterGenericTranslateArrayByLc(REQUESTSTAGE_LIST, REQUESTSTAGE_I18N_KEY, lc, false);
    },
    getterRequestOriginsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of request origins object with translations
      return getters.getterGenericTranslateArrayByLc(REQUESTORIGIN_LIST, REQUESTORIGIN_I18N_KEY, lc, true);
    },
    getterRequestValidationMethodsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of request origins object with translations
      return getters.getterGenericTranslateArrayByLc(REQUESTVALIDATIONMETHOD_LIST, REQUESTVALIDATIONMETHOD_I18N_KEY, lc, false);
    },
    getterRequestIdentityStatesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of request origins object with translations
      return getters.getterGenericTranslateArrayByLc(REQUESTIDENTITYSTATE_LIST, REQUESTIDENTITYSTATE_I18N_KEY, lc, false);
    },
    getterRequestAuthorizationStatesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of request origins object with translations
      return getters.getterGenericTranslateArrayByLc(REQUESTAUTHORIZATIONSTATE_LIST, REQUESTAUTHORIZATIONSTATE_I18N_KEY, lc, false);
    },
    getterRequestNotificationStatesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of request origins object with translations
      return getters.getterGenericTranslateArrayByLc(REQUESTNOTIFICATIONSTATE_LIST, REQUESTNOTIFICATIONSTATE_I18N_KEY, lc, false);
    },
    getterRequestPreparationStatesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of request origins object with translations
      return getters.getterGenericTranslateArrayByLc(REQUESTPREPARATIONSTATE_LIST, REQUESTPREPARATIONSTATE_I18N_KEY, lc, false);
    },
    getterRequestCategoriesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of request category objects with translations
      return getters.getterGenericTranslateArrayByLc(REQUESTCATEGORY_LIST, REQUESTCATEGORY_I18N_KEY, lc, true);
    },
    getterRequestCategoriesByOriginIDAsArrayByLc: (state, getters, rootState, rootGetters) => (originID, lc) => {
      // returns an array of request category objects with translations
      return getters.getterGenericTranslateWithMapArrayByLc(originID, REQUEST_ORIGIN_CATEGORY_MAP, REQUESTCATEGORY_LIST, REQUESTCATEGORY_I18N_KEY, lc, true);
    },
    getterProofTargetsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of incident detector objects with translations
      return getters.getterGenericTranslateArrayByLc(PROOFTARGET_LIST, PROOFTARGET_I18N_KEY, lc, false);
    },
    getterProofStagesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of incident stage object with translations
      return getters.getterGenericTranslateArrayByLc(PROOFSTAGE_LIST, PROOFSTAGE_I18N_KEY, lc, false);
    },
    getterUsertaskStagesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of incident stage object with translations
      return getters.getterGenericTranslateArrayByLc(USERTASKSTAGE_LIST, USERTASKSTAGE_I18N_KEY, lc, false);
    },
    getterIncidentStagesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of incident stage object with translations
      return getters.getterGenericTranslateArrayByLc(INCIDENTSTAGE_LIST, INCIDENTSTAGE_I18N_KEY, lc, false);
    },
    getterIncidentImpactLevelsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of incident impact level object with translations
      return getters.getterGenericTranslateArrayByLc(IMPACTLEVEL_LIST, IMPACTLEVEL_I18N_KEY, lc, false);
    },
    getterIncidentDetectorsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of incident detector objects with translations
      return getters.getterGenericTranslateArrayByLc(INCIDENTDETECTOR_LIST, INCIDENTDETECTOR_I18N_KEY, lc, false);
    },
    getterIncidentTitlesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of incident detector objects with translations
      return getters.getterGenericTranslateArrayByLc(INCIDENTTITLE_LIST, INCIDENTTITLE_I18N_KEY, lc, true);
    },
    getterIncidentReasonsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of incident detector objects with translations
      return getters.getterGenericTranslateArrayByLc(INCIDENTREASON_LIST, INCIDENTREASON_I18N_KEY, lc, true);
    },
    getterIncidentNotificationStatesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of incident detector objects with translations
      return getters.getterGenericTranslateArrayByLc(INCIDENTNOTIFICATIONSTATE_LIST, INCIDENTNOTIFICATIONSTATE_I18N_KEY, lc, true);
    },
    getterIncidentReportStatesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of incident detector objects with translations
      return getters.getterGenericTranslateArrayByLc(INCIDENTREPORTSTATE_LIST, INCIDENTREPORTSTATE_I18N_KEY, lc, true);
    },
    getterIncidentRiskCategoriesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(INCIDENTRISKCATEGORY_LIST, INCIDENTRISKCATEGORY_I18N_KEY, lc, false);
    },
    getterIncidentRiskConsequencesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(INCIDENTRISKCONSEQUENCE_LIST, INCIDENTRISKCONSEQUENCE_I18N_KEY, lc, false);
    },
    getterActivityDocStagesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(ACTIVITYDOCSTAGE_LIST, ACTIVITYDOCSTAGE_I18N_KEY, lc, false);
    },
    getterProcedureStagesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(PROCEDURESTAGE_LIST, PROCEDURESTAGE_I18N_KEY, lc, false);
    },
    getterProcedureTargetsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(PROCEDURETARGET_LIST, PROCEDURETARGET_I18N_KEY, lc, false);
    },
    getterDsfaStagesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(DSFASTAGE_LIST, DSFASTAGE_I18N_KEY, lc, false);
    },
    getterDsfaTargetsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(DSFATARGET_LIST, DSFATARGET_I18N_KEY, lc, false);
    },

    getterThresholdCriteriaAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(THRESHOLDCRITERIA_LIST, THRESHOLDCRITERIA_I18N_KEY, lc, true, "sequence");
    },
    getterThresholdLevelsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(THRESHOLDLEVEL_LIST, THRESHOLDLEVEL_I18N_KEY, lc, true, "sequence");
    },

    getterAssetCategoriesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(ASSETCATEGORY_LIST, ASSETCATEGORY_I18N_KEY, lc, false);
    },
    getterAssetTargetsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(ASSETTARGET_LIST, ASSETTARGET_I18N_KEY, lc, false);
    },
    getterRiskCataloguesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(RISKCATALOGUE_LIST, RISKCATALOGUE_I18N_KEY, lc, false);
    },
    getterRiskBaseValuesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(RISKBASEVALUE_LIST, RISKBASEVALUE_I18N_KEY, lc, false);
    },
    getterRiskDangersAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(RISKDANGER_LIST, RISKDANGER_I18N_KEY, lc, true);
    },
    getterRiskStagesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(RISKSTAGE_LIST, RISKSTAGE_I18N_KEY, lc, false);
    },
    getterRiskLevelImpactsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(RISKLEVELIMPACT_LIST, RISKLEVEL_I18N_KEY, lc, false);
    },
    getterRiskLevelPropabilitiesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(RISKLEVELPROPABILITY_LIST, RISKLEVEL_I18N_KEY, lc, false);
    },
    getterRiskLevelTotalsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(RISKLEVELTOTAL_LIST, RISKLEVEL_I18N_KEY, lc, false);
    },
    getterLocationTypesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(LOCATIONTYPE_LIST, LOCATIONTYPE_I18N_KEY, lc, false);
    },
    getterGdprOfficersTypesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterGenericTranslateArrayByLc(OFFICERTYPE_LIST, OFFICERTYPE_I18N_KEY, lc, false);
    },

    ////////////////////////////////////////////////////////////

    // Generic translations by vue-i18n
    getterGenericTranslateArrayByLc: (state, getters, rootState, rootGetters) => (srcarray, key, lc, sortflag, sortcolumn = "display_name") => {
      // returns an array of data subjects objects with translations
      var array = [];
      var sortColumnExits = true;

      try {
        for (let item in srcarray) {
          var obj = {
            ...srcarray[item],
            id: item,
            i18n_key: key,
            display_name: i18n.t(key + srcarray[item].name, lc),
            display_description: i18n.t(key + srcarray[item].description, lc)
          };

          if (Object.prototype.hasOwnProperty.call(obj, 'printtext') == true) {
            obj.display_printtext = i18n.t(key + srcarray[item].printtext, lc);
          }

          array.push(obj);

          if(sortColumnExits === true)
            sortColumnExits = Object.prototype.hasOwnProperty.call(obj, sortcolumn);
        }

        // Sort A-Z based on display_name
        if (sortflag && sortColumnExits) {
          array.sort(rootGetters["HelperManager/getterArrayOfObjectDynamicSort"](sortcolumn));
        }

      } catch (err) {
        console.error("getterGenericTranslateArrayByLc", err);
        array = [];
      }

      return array;
    },

    getterGenericTranslateWithMapArrayByLc: (state, getters, rootState, rootGetters) => (maprefid, maparray, srcarray, key, lc, sortflag, sortcolumn = "display_name") => {
      // returns an array of data subjects objects with translations
      var array = [];
      var sortColumnExits = true;

      try {
        if (maprefid && maparray[maprefid] !== undefined) {
          for (const item of maparray[maprefid]) {
            var obj = {
              ...srcarray[item],
              id: item,
              i18n_key: key,
              display_name: i18n.t(key + srcarray[item].name, lc),
              display_description: i18n.t(key + srcarray[item].description, lc)
            };

            array.push(obj);

            if(sortColumnExits === true)
              sortColumnExits = Object.prototype.hasOwnProperty.call(obj, sortcolumn);
          }

          // Sort A-Z based on display_name
          if (sortflag && sortColumnExits) {
           array.sort(rootGetters["HelperManager/getterArrayOfObjectDynamicSort"](sortcolumn));
          }
        }
      } catch (err) {
        console.error("getterGenericTranslateWithMapArrayByLc", err);
        array = [];
      }

      return array;
    },
    getterInternalSimpleTranslateArrayByLc: (state, getters, rootState, rootGetters) => (srcarray, key, lc, sortflag, sortcolumn = "display_name") => {
      // returns an array of data subjects objects with translations
      var array = [];
      var sortColumnExits = true;

      try {
        for (const item of srcarray) {
          array.push({
            name: item,
            display_name: i18n.t(key + item, lc),
          });

          if(sortColumnExits === true)
            sortColumnExits = Object.prototype.hasOwnProperty.call(array[array.length-1], sortcolumn);
        }

        // Sort A-Z based on display_name
        if (sortflag && sortColumnExits) {
          array.sort(rootGetters["HelperManager/getterArrayOfObjectDynamicSort"](sortcolumn));
        }
      } catch (err) {
        console.error("getterGenericTranslateWithMapArrayByLc", err);
        array = [];
      }

      return array;
    }
  }
};