// setup
import i18n from '@/translations/index';

import {
  LANGUAGES_ARRAY,
  DEFAULT_LANGUAGE
} from '@/config/config.locales';

export default {
  namespaced: true,
  state: {
    "lang": DEFAULT_LANGUAGE,
    "valid": false
  },
  mutations: {
    SET_LOCALE(state, lang) {
      // console.debug("SET_LOCALE", lang);
      state.lang = lang;
      state.valid = true;
    }
  },
  actions: {
    //set the prefered language for the app
    setLocale({
      commit,
      getters
    }, lang) {
      if (getters.isSupportedLanguage(lang)) {
        i18n.locale = lang.lc;
        commit('SET_LOCALE', lang);
      }
    },

    setLocaleByLc({
      commit,
      getters,
      dispatch
    }, lc_value) {
      const languageAry = getters.languages.filter(
        language => language.lc == lc_value.toLowerCase()
      );

      if (languageAry.length) {
        dispatch('setLocale', languageAry[0]);
        return;
      }
    },

    detectBrowserLanguage({
      state,
      getters,
      dispatch
    }, payload) {
      // previusly selected
      if (state.valid === true) {
        return;
      }

      // get browser language
      const userLanguage = payload.split("-");

      if (Array.isArray(userLanguage)) {
        for (var i = 0; i < userLanguage.length; i++) {
          const userLanguageObj = getters.languages.filter(
            language => language.lc == userLanguage[i].toLowerCase()
          );
          if (userLanguageObj.length) {
            dispatch('setLocale', userLanguageObj[0]);
            return;
          }
        }
      }
    }
  },
  getters: {
    getterLanguageAlpha2Code: (state) => state.lang.lc,
    getterLanguageLocale: (state) => {
      return state.lang.lc +"-" + state.lang.uc;
    },
    getterLanguageBCP47Tag: (state) => {
      return state.lang.bcp47;
    },
    getterDefaultLanguageAlpha2Code: (state) => DEFAULT_LANGUAGE.lc,

    isSupportedLanguage: (state, getters) => (lang) => {
      //check if the choosen language is supported
      return getters.languages.map(language => language.lc).includes(lang.lc);
    },

    isSupportedAlpha2Code: (state, getters) => (langcode) => {
      //check if the choosen language is supported
      return getters.languages.map(language => language.lc).includes(langcode);
    },

    lang: (state) => state.lang,
    languages: state => LANGUAGES_ARRAY, // return an array of languages
  }
};