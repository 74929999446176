export const REPOSITORY_AT_LIST = {
    "_id": "repository",
    "type": "template",
    "subtype": "files",
    "standard": true,
    "creator": "DAPAX",
    "created_at": "2025-03-23",
    "updated_at": "2025-03-23",
    "keyid": "repository",
    "repository": {
        "justiceareas": [
            "AT"
        ],

        "name": "Musterdokumentvorlagen",
        "description": "Musterdokumentvorlagen",

        "I18N_KEY": "repository.",
        "LIST": [{
                "filename": "dsg_at_2025.pdf",
                "name": "dsg_at_2025_name",
                "description": "dsg_at_2025_description",
                "category": "ID_DOCUMENTCATEGORY_INFORMATION",
                "type": "ID_DOCUMENTTYPE_ADDITIONALDOCUMENT"
            }
        ],
        "I18N": {
            "languages": [
                "de",
                "gb"
            ],
            "defaultlang": "de",
            "translations": {
                "de": {
                  "dsg_at_2025_name": "Datenschutzgesetz Österreich Stand BGBl. I Nr. 70/2024",
                  "dsg_at_2025_description": "Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (Datenschutzgesetz – DSG) BGBl. I Nr. 70/2024 (NR: GP XXVII AB 2594 S. 268. BR: AB 11514 S. 968.)"
                 },
                "gb": {
                    "dsg_at_2025_name": "DDatenschutzgesetz Österreich Stand BGBl. I Nr. 70/2024",
                    "dsg_at_2025_description": "Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (Datenschutzgesetz – DSG) BGBl. I Nr. 70/2024 (NR: GP XXVII AB 2594 S. 268. BR: AB 11514 S. 968.)"
                  }
            }
        }
    }
};
