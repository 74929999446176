import {
  EMPTY_ASSIGNTODATA
}  from '@/config/config.constants';

import CommonUtils from "@/utils/common.utils";

export default {
  // Global Application related state to control startup
  getterIsFirstRun: state => state.Application.is_first_run,
  getterIsInitialDataComplete: state => state.Application.is_initial_data_complete,
  getterIsDarkThemeMode: state => state.Application.is_dark_theme,
  getterIsReference: state => state.Application.is_reference,

  // Settings
  getterHasRequestorHint: state => state.Application.settings.hasRequestorHint,

  // Runtime state
  getterIsBackendOnline: state => state.Application.Runtime.is_backend_online,
  getterIsIgnoreLicenceGracePeriodState: state => state.Application.Runtime.is_ignore_license_grace_period,

  getterLoadingState: state => state.Application.Runtime.is_loading,

  getterAutoInitWithDataMaps: state => state.Application.Runtime.auto_init_with_datamaps,

  getterActiveComponentName: state => state.Application.Runtime.active_component,

  getterIsDashboardActiveComponentName: state => {
    return ((state.Application.Runtime.active_component == 'Dashboard') || (state.Application.Runtime.active_component == false));
  },
  getterBreadcrumbsAsArray: state => {
    return state.Application.Runtime.breadcrumbs;
  },
  getterContentComponent: state => state.Application.Runtime.contentComponent,

  getterAlertMessage: state => state.Application.Runtime.alert_message,
  getterIsAlertMessageActive: state => state.Application.Runtime.alert_message.active || false,

  getterMessageOkDialog: state => state.Application.Runtime.message_ok_dialog,
  getterMessageConfirmDialog: state => state.Application.Runtime.message_confirm_dialog,
  getterInProgressDialog: state => state.Application.Runtime.progress_dialog,

  // Support Info related state data
  getterSupportLogo: state => {
    if (!state.Application.SupportLogo.imageData) {
      return { imageData: require("@/assets/defaultsupportlogo.png") };
    } else {
      return state.Application.SupportLogo;
    }
  },

  getterSupportData: state => state.Application.SupportData,

  getterEmptyAssignToObject: () => {
    return {...EMPTY_ASSIGNTODATA};
  },

  getterIsCurrentUserAssignedToObject:(state, getters, rootState, rootGetters) => (dataObj) => {
    try {
      if (dataObj === undefined || !Object.prototype.hasOwnProperty.call(dataObj, "assigned_to") )
        throw "data object is undefined or incomplete";

      return (rootGetters["SessionManager/getterCurrentUsername"] == dataObj.assigned_to);
    } catch (error) {
      console.error("getterIsCurrentUserAssignedToObject - Error : ", error);
      return false;
    }
  },
};
