import dayjs from 'dayjs';

import CommonUtils from '@/utils/common.utils.js';

import {DEFAULT_UI_FONTSIZE, DEFAULT_UI_CARDTITLE, DEFAULT_UI_TABSIZE} from '@/config/config.constants.js';

import { mapGetters } from "vuex";

import DatetimeUtils from '@/utils/datetime.utils';

export const Helpers = {
  methods: {
    getContactStringFromObject(contactObj, namekey) {
      // console.debug("getContactStringFromObject - contactObj", contactObj);

      if (!namekey.length) {
        namekey = 'name';
      }

      if (this.isObjectEmpty(contactObj) || !Object.prototype.hasOwnProperty.call(contactObj, namekey)) {
        return '';
      }

      if (contactObj[namekey].length <= 0) {
        return '';
      }

      let contactTextStr = contactObj[namekey] + '\r';

      if (Object.prototype.hasOwnProperty.call(contactObj, "responsible_role")) {
        contactTextStr += contactObj.responsible_role + '\r';
      }

      contactTextStr +=
        contactObj.street + " | " + contactObj.zipcode + ' ' + contactObj.city + ' (' + contactObj.country + ')' + '\r' +
        this.$t("contactinfo.label_phone") + ": " + contactObj.phone + '\r' +
        this.$t("contactinfo.label_email") + ": " + contactObj.email_address;

      return contactTextStr;
    },
    getShortContactStringFromObject(contactObj) {
      // console.debug("getShortContactStringFromObject - contactObj", contactObj);
      let namekey = 'name';

      if (this.isObjectEmpty(contactObj) || !Object.prototype.hasOwnProperty.call(contactObj, namekey) || this.isStringEmpty(contactObj[namekey])) {
        return '';
      }

      let contactTextStr = contactObj[namekey] + '\r';

      if (Object.prototype.hasOwnProperty.call(contactObj, "type_display_name")) {
        contactTextStr += ": " + contactObj.type_display_name + '\r';
      }

      return contactTextStr;
    },
    extractNumberFromKeyId(keyid) {
      return CommonUtils.extractNumberFromKeyId(keyid);
    },
    translateReferenceScopeName(scope) {
      try {
        return this.translate("mydocuments", "label_scope_" + scope);
      }
      catch (err) {
        console.error("translateReferenceScopeName - Invalid scope", scope);
        return "-";
      }
    },
    getIconSymbolByReferenceScope(referenceObj) {
      if (!Object.prototype.hasOwnProperty.call(referenceObj, "scope"))
        return '';

      switch (referenceObj.scope) {
        case 'contact':
          return this.getContactIcon;

        case 'proof':
          return this.getProofIcon;

        case 'request':
          return this.getRequestIcon;

        case 'incident':
          return this.getIncidentIcon;

        case 'activitydoc':
          return this.getActivitydocIcon;

        case 'risk':
          return this.getRiskIcon;

        case 'dsfa':
          return this.getDsfaIcon;

        case 'procedure':
          return this.getProcedureIcon;

        case 'contractpartner':
          return this.getContractpartnerIcon;

        case 'asset':
          return this.getAssetIcon;

        case 'process':
          return this.getProcessIcon;

        case 'area':
          return this.getAreaIcon;

        case 'entity':
          return this.getEntityIcon;

        case 'location':
          return this.getLocationIcon;

        case 'officer':
          return this.getOfficerIcon;

        case 'usertask':
          return this.getTaskIcon;
      }

      return '';
    },
    getColorByReferenceScope(referenceObj) {
      if (!Object.prototype.hasOwnProperty.call(referenceObj, "scope"))
        return '';

      switch (referenceObj.scope) {
        case 'proof':
          return 'color--proof';

        case 'dsfa':
          return 'color--dsfa';

        case 'request':
          return 'color--request';

        case 'incident':
          return 'color--incident';

        case 'activitydoc':
          return 'color--activity';

        case 'risk':
          return 'color--risk';

        case 'contact':
          return 'color--contactbook';

        case 'procedure':
          return 'color--procedure';

        case 'contractpartner':
          return 'color--contractpartner';

        case 'asset':
          return 'color--asset';

        case 'process':
          return 'color--process';

        case 'area':
          return 'color--area';

        case 'location':
          return 'color--location';

        case 'entity':
          return 'color--entity';

        case 'task':
          return 'color--usertask';

        case 'usertask':
          return 'color--usertask';

        case 'officer':
          return 'color--entity';
      }

      return '';
    },
    getIsOther(idvalue) {
      return CommonUtils.getIsOther(idvalue);
    },
    removeDuplicatesFromArray(array) {
      return CommonUtils.removeDuplicatesFromArray(array);
    },
    translate(section, key) {
      //get the translations for the selected locale
      if (this.$te(`${section}.${key}`)) {
        return this.$t(`${section}.${key}`);
      }
      return key;
    },
    truncateString(str, n) {
      return CommonUtils.truncateString(str, n);
    },
    isStringEmpty(str) {
      return CommonUtils.isStringEmpty(str);
    },
    isObjectEmpty(obj) {
      return CommonUtils.isObjectEmpty(obj);
    },
    isArrayEmpty(ary) {
      return CommonUtils.isArrayEmpty(ary);
    },
    standardize_color(str) {
      var a = document.createElement('div');
      a.classList.add(str);
      var colors = window.getComputedStyle(document.body.appendChild(a)).color.match(/\d+/g).map(function (a) { return parseInt(a, 10); });
      document.body.removeChild(a);
      return (colors.length >= 3) ? '#' + (((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2]).toString(16).substr(1)) : false;
    },
    getTrafficLightColorOfFive(status) {
      switch (status) {
        case 1:
          return '#00AA00'; //green

        case 2:
          return '#99c140';

        case 3:
          return '#e7b416';  // yellow

        case 4:
          return '#db7b2b';

        case 5:
          return '#DD0000';  // red

        default:
          return 'grey lighten-1';
      }
    },
    getProofTrafficLightColorOfThree(status) {
      switch (status) {
        case 3:
          return '#00AA00'; //green

        case 2:
          return '#e7b416';  // yellow

        case 1:
          return '#DD0000';  // red

        default:
          return 'grey lighten-1';
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 'fail':
          return 'red darken-2';

        case 'warn':
          return 'blue darken-2';

        case 'ok':
          return 'green darken-2';

        default:
          return 'grey darken-1';
      }
    },

    //

    getIsValidTime(time, format = "hh:mm") {
      return dayjs(time, format).isValid();
    },
    getIsValidDate(date, format = "YYYY-MM-DD") {
      return dayjs(date, format).isValid();
    },
    getIsValidDateTime(datetime, format = "YYYY-MM-DD hh:mm") {
      return dayjs(datetime, format).isValid();
    },
    getFormatDate(datevalue) {
      return DatetimeUtils.getFormatDate(datevalue);
    },
    getFormatDateTime(datetimevalue) {
      return DatetimeUtils.getFormatDateTime(datetimevalue);
    },
    getCurrentDate() {
      return DatetimeUtils.getCurrentDate();
    },
    //

    hasDefaultLanguageContent(dataObj, keyname) {
      try {
        if(!Object.prototype.hasOwnProperty.call(dataObj, "i18n"))
          return false;

        const defaultlang = dataObj.i18n.defaultlang;
        const dataLanguage = dataObj.display_translation.lang;

        if(this.isStringEmpty(dataObj.display_translation[keyname]) && defaultlang == dataLanguage)
          return true;

        if(defaultlang != dataLanguage)
        {
          if(!Object.prototype.hasOwnProperty.call(dataObj.i18n.translations, defaultlang))
            return true;

          if(this.isStringEmpty(dataObj.i18n.translations[defaultlang][keyname]))
            return true;
        }

        return false;
      } catch (error) {
        return false;
      }
    },

    translateMultiLanguageContent(datObj, newLang, oldLang, editMode) {
      if(this.isObjectEmpty(datObj))
        return datObj;

      // console.debug("watch.dataLanguage - newLang, oldLang", newLang, oldLang);
      // console.debug("watch.dataLanguage - dataLanguage", this.dataLanguage);
      // console.debug("watch.dataLanguage - formData", merge({}, datObj));

      if(oldLang != newLang) {
        if(editMode) {
          if(!this.isStringEmpty(oldLang)) {
            if(!Object.prototype.hasOwnProperty.call(datObj.i18n.translations, oldLang)) {
              datObj.i18n.translations[oldLang] = {
                name: "",
                description: ""
              };
            }

            datObj.i18n.translations[oldLang].name = datObj.display_translation.name;
            datObj.i18n.translations[oldLang].description = datObj.display_translation.description;
          }
        }
      }

      // reset display_translations and fill with new dataLanguage data
      if (!this.isStringEmpty(newLang)) {
        if (Object.prototype.hasOwnProperty.call(datObj.i18n.translations, newLang)) {
          datObj.display_translation.name = datObj.i18n.translations[newLang].name;
          datObj.display_translation.description = datObj.i18n.translations[newLang].description;
        }
        else {
          if(editMode) {
            datObj.i18n.translations[newLang] = {
              name: "",
              description: ""
            };
          }

          datObj.display_translation.name = "";
          datObj.display_translation.description = "";
        }
      }
      else {
        datObj.display_translation.name = "";
        datObj.display_translation.description = "";
      }

      return datObj;
    },
  },
  computed: {
    activeLanguageObserver() {
      return this.$i18n.locale;
    },
    getVuetifyMoneyOptions() {
      return {
        locale: this.getterLanguageLocale,
        prefix: "",
        suffix: "€",
        length: 11,
        precision: 0
      };
    },
    getDefaultFontsize() {
      return DEFAULT_UI_FONTSIZE;
    },
    getDefaultCardTitlesize() {
      return DEFAULT_UI_CARDTITLE;
    },
    getDefaultTabsHeight() {
      return DEFAULT_UI_TABSIZE;
    },
    ...mapGetters({
      getterLanguageLocale: "TranslationManager/getterLanguageLocale",
    }),

  }
};